export enum API_PATH {
  LOGIN = '/api/auth/signin',
  CHANGE_PASSWORD = '/api/auth/reset/password',
  LOGOUT = '/api/auth/signout',
  PROFILE_SESSION = '/api/profile/session',
  PROFILE_ACCESS = '/api/profile/check/access',
  USER_MENU = '/api/bo/general/mainmenu',
  APPLICATION = "/api/trx/application",
  BANK_CHECK = "/api/trx/bank/checking",
  APPROVAL = '/api/trx/approval',
  CONTRACT = '/api/trx/contract',
  DISBURSE = '/api/trx/disburse',
  PAYMENT = '/api/trx/payment',
  PAYMENT_SCHEDULE = '/api/trx/payment/schedule',
  TRANSACTION = '/api/trx/transaction',
  ACTIVITY_USER = '/api/backoffice/activity/log',
  MESSAGE_LOG = '/api/trx/message/log',
  MESSAGE_WHATSAPP = '/api/trx/message/whatsapp',
  MESSAGE_RECEIVER = '/api/trx/message/receiver',
  CONTACT = '/api/contact',
  QUESTION = '/api/app/question'
}
